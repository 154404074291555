import { ERC20__factory } from '@modernlion/marketplace-registry'
import { getPayInfo, rd } from '@modernlion/solidity-registry'

import { CHAIN_ID } from '@/shared/constants'
import {
  DEV_WETH_ADDRESS,
  DEV_WMATIC_ADDRESS,
  PROD_WETH_ADDRESS,
  PROD_WMATIC_ADDRESS,
} from '@/shared/constants/tokenAddress'
import { getEnvValue } from '@/shared/utils'

import { getWeb3Provider } from './provider'

export const getMaticBalance = async (accountAddress: string): Promise<string | Error> => {
  const web3Provider = getWeb3Provider()
  if (web3Provider instanceof Error) return web3Provider

  try {
    const balance = await web3Provider.getBalance(accountAddress)
    const payInfo = getPayInfo('MATIC')
    return rd(balance, payInfo.decimals)
  } catch (e) {
    console.error('Get Matic Balance Error', e)
    return new Error('Get Matic Balance Error')
  }
}

export const getWMaticBalance = async (accountAddress: string): Promise<string | Error> => {
  const isDev = getEnvValue('mode') === 'development'
  const wmaticAddress = isDev ? DEV_WMATIC_ADDRESS : PROD_WMATIC_ADDRESS

  const web3Provider = getWeb3Provider()
  if (web3Provider instanceof Error) return web3Provider

  try {
    const balance = await ERC20__factory.connect(wmaticAddress, web3Provider).balanceOf(
      accountAddress,
    )
    const payInfo = getPayInfo('MATIC')
    const result = rd(balance, payInfo.decimals)
    return result
  } catch (e: unknown) {
    console.error('Get WMatic Balance Error', e)
    if (e instanceof Error) {
      return e
    }
    return new Error('Unknown error')
  }
}

export const getEtherBalance = async (accountAddress: string): Promise<string | Error> => {
  const web3Provider = getWeb3Provider(CHAIN_ID.ethereum)
  if (web3Provider instanceof Error) return web3Provider

  try {
    const balance = await web3Provider.getBalance(accountAddress)
    const payInfo = getPayInfo('ETH')
    return rd(balance, payInfo.decimals)
  } catch (e) {
    console.error('Get Ether Balance Error', e)
    return new Error('Get Ether Balance Error')
  }
}

export const getWEtherBalance = async (accountAddress: string): Promise<string | Error> => {
  const isDev = getEnvValue('mode') === 'development'
  const wethAddress = isDev ? DEV_WETH_ADDRESS : PROD_WETH_ADDRESS

  const web3Provider = getWeb3Provider(CHAIN_ID.ethereum)
  if (web3Provider instanceof Error) return web3Provider

  try {
    const balance = await ERC20__factory.connect(wethAddress, web3Provider).balanceOf(
      accountAddress,
    )

    const payInfo = getPayInfo('ETH')
    const result = rd(balance, payInfo.decimals)
    return result
  } catch (e: unknown) {
    console.error('Get WEther Balance Error', e)

    if (e instanceof Error) {
      return e
    }
    return new Error('Unknown error')
  }
}
