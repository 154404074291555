import { useRouter } from 'next/router'
import { useCallback, useEffect, useMemo, useReducer, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { POLYGON_LISTING_PAYMENT_TYPE } from '../constants'
import {
  useGetCurrentAccountHiddenItemCollections,
  useGetCurrentAccountItemCollections,
  useGetCurrentAccountLikedItemCollections,
  useGetPublicAccountItemCollections,
  useGetPublicAccountLikedItemCollections,
} from '../services/accounts'
import { accountItemFilterSlice, accountItemFilterState } from '../store/filters'
import { useAppSelector } from '../store/hooks'
import { selectHightestOffers } from '../store/trades'
import { selectCurrentAccountAddress } from '../store/wallet'
import {
  FilterChip,
  IChipMaxPriceType,
  IChipMinPriceType,
  ICollection,
  ListingPaymentType,
} from '../types'
import { useMemoAccountItemFilterQueryParams } from './useMemoAccountItemFilterQueryParams'
import { usePriceRangeFilter } from './usePriceRangeFilter'

// TODO @고영욱 필터 관련 코드(현재 사용X)
export const useAccountItemFilter = (
  type: 'unhidden' | 'hidden' | 'liked' = 'unhidden',
  isCurrentAccountPage = true,
) => {
  const { t } = useTranslation(['common'])
  const [isFilterVisible, setIsFilterVisible] = useState(false)

  const { pathname, query } = useRouter()
  const { accountAddress } = query as { accountAddress?: string }
  const currentAccountAddress = useAppSelector(selectCurrentAccountAddress)

  const highestOffers = useAppSelector(selectHightestOffers)

  const { data: accountCollections } = useGetCurrentAccountItemCollections(
    pathname,
    currentAccountAddress,
    type === 'unhidden' && isCurrentAccountPage,
  )

  const { data: accountHiddenCollections } = useGetCurrentAccountHiddenItemCollections(
    pathname,
    currentAccountAddress,
    type === 'hidden' && isCurrentAccountPage,
  )

  const { data: accountLikedCollections } = useGetCurrentAccountLikedItemCollections(
    pathname,
    currentAccountAddress,
    type === 'liked' && isCurrentAccountPage,
  )

  const { data: publicAccountCollections } = useGetPublicAccountItemCollections(
    pathname,
    type === 'unhidden' && !isCurrentAccountPage,
    accountAddress,
  )

  const { data: publicAccountLikedCollections } = useGetPublicAccountLikedItemCollections(
    pathname,
    type === 'liked' && !isCurrentAccountPage,
    accountAddress,
  )

  const collections = useMemo(() => {
    const mappings: {
      [key in 'currentAccount' | 'publicAccount']: {
        [key in 'hidden' | 'liked' | 'unhidden']: ICollection[] | undefined
      }
    } = {
      currentAccount: {
        hidden: accountHiddenCollections?.collections,
        liked: accountLikedCollections?.collections,
        unhidden: accountCollections?.collections,
      },
      publicAccount: {
        unhidden: publicAccountCollections?.collections,
        liked: publicAccountLikedCollections?.collections,
        hidden: undefined,
      },
    }
    return mappings[isCurrentAccountPage ? 'currentAccount' : 'publicAccount'][type]
  }, [
    accountCollections?.collections,
    accountHiddenCollections?.collections,
    accountLikedCollections?.collections,
    isCurrentAccountPage,
    publicAccountCollections?.collections,
    publicAccountLikedCollections?.collections,
    type,
  ])

  const [state, dispatch] = useReducer(accountItemFilterSlice.reducer, accountItemFilterState)

  const {
    removeChip,
    initPrice,
    setMaxPrice,
    setMinPrice,
    setIsClickChip,
    setOrderAndOrderBy,
    setOwnCollections,
    setPaymentType,
    toggleIsListing,
    toggleHasOffer,
    initAccountItemFilter,
    removeCollectionFilter,
    toggleSelectedCollection,
  } = accountItemFilterSlice.actions

  const debouncedQueryParams = useMemoAccountItemFilterQueryParams(state)

  const [filterQueryParams, setFilterQueryParams] = useState(debouncedQueryParams)

  const sortOptions = useMemo(() => {
    if (type === 'liked') {
      return [
        { text: t('common.label-list-like-recent'), order: 'createdAt', orderBy: 'DESC' },
        { text: t('common.label-list-like-oldest'), order: 'createdAt', orderBy: 'ASC' },
      ]
    }
    return [
      { text: t('common.label-list-collect-recent'), order: 'ownerUpdatedAt', orderBy: 'DESC' },
    ]
  }, [type, t])

  const [isClickSort, setIsClickSort] = useState(false)

  useEffect(() => {
    dispatch(setOwnCollections(collections))
  }, [collections, setOwnCollections])

  const handleOrderAndSortBy = useCallback(
    ({ order, orderBy }: { order: string; orderBy: string }) => {
      if (state.orderBy !== orderBy) {
        setIsClickSort(true)
      } else {
        setIsClickSort(false)
      }

      dispatch(setOrderAndOrderBy({ order, orderBy }))
    },
    [setOrderAndOrderBy, state.orderBy],
  )

  const handleToggleIsListing = useCallback(() => dispatch(toggleIsListing()), [toggleIsListing])
  const handleToggleHasOffer = useCallback(() => {
    dispatch(toggleHasOffer(highestOffers))
  }, [highestOffers, toggleHasOffer])
  const handleMaxPrice = useCallback((price: string) => dispatch(setMaxPrice(price)), [setMaxPrice])
  const handleMinPrice = useCallback((price: string) => dispatch(setMinPrice(price)), [setMinPrice])
  const handlePaymentType = useCallback(
    (paymentType: ListingPaymentType) => dispatch(setPaymentType({ paymentType })),
    [setPaymentType],
  )
  const handleCollectionClick = useCallback(
    (collection: ICollection) => {
      dispatch(toggleSelectedCollection(collection))
    },
    [toggleSelectedCollection],
  )
  const handleRemoveCollectionFilter = useCallback(
    (collectionAddress: string) => {
      dispatch(removeCollectionFilter(collectionAddress))
    },
    [removeCollectionFilter],
  )

  const {
    isError,
    maxPriceValue,
    minPriceValue,
    handleChangeMinPrice,
    handleChangeMaxPrice,
    handleClearInputValue,
  } = usePriceRangeFilter({
    maxPrice: state.maxPrice,
    minPrice: state.minPrice,
    handleMaxPrice,
    handleMinPrice,
  })

  const handleClickChip = useCallback(
    (isClickChip: boolean) => {
      dispatch(setIsClickChip(isClickChip))
    },
    [setIsClickChip],
  )

  const handleRemoveChip = useCallback(
    (chip: FilterChip) => {
      dispatch(setIsClickChip(true))
      dispatch(removeChip(chip))
      if (Object.keys(chip)[0] === 'minPriceChip' || Object.keys(chip)[0] === 'maxPriceChip') {
        const priceChip = Object.keys(chip)[0] as keyof FilterChip
        const chipType = chip[priceChip] as IChipMinPriceType | IChipMaxPriceType
        handleClearInputValue(chipType.type as 'minPrice' | 'maxPrice')
      }
    },
    [handleClearInputValue, removeChip, setIsClickChip],
  )

  const handleInitPrice = useCallback(() => {
    dispatch(initPrice())
    handleClearInputValue()
  }, [handleClearInputValue, initPrice])

  const handleClear = useCallback(() => {
    dispatch(initAccountItemFilter())
    dispatch(setOwnCollections(collections))
    handleInitPrice()
    handleClickChip(false)
    setFilterQueryParams({
      isListing: false,
      hasOffer: false,
      paymentType: POLYGON_LISTING_PAYMENT_TYPE.MATIC,
      order: undefined,
      orderBy: 'DESC',
      minPrice: undefined,
      maxPrice: undefined,
      collectionAddresses: '',
    })
  }, [collections, handleClickChip, handleInitPrice, initAccountItemFilter, setOwnCollections])

  const handleClickFilterButton = useCallback(
    (value: boolean) => {
      setIsFilterVisible(value)
      setIsClickChip(false)
    },
    [setIsClickChip],
  )

  return {
    isClickSort,
    filterQueryParams,
    setFilterQueryParams,
    debouncedQueryParams,
    handleToggleIsListing,
    handleToggleHasOffer,
    maxPriceValue,
    minPriceValue,
    isInputPriceError: isError,
    handleChangeMinPrice,
    handleChangeMaxPrice,
    handlePaymentType,
    state,
    handleClickChip,
    handleRemoveChip,
    handleClear,
    isFilterVisible,
    setIsFilterVisible,
    handleClickFilterButton,
    sortOptions,
    handleOrderAndSortBy,
    handleInitPrice,
    collectionList: collections,
    handleCollectionClick,
    handleRemoveCollectionFilter,
  }
}
