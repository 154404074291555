import { useRouter } from 'next/router'

import { useGetCoinPrice } from '../services/coin'
import { useAppSelector } from '../store/hooks'
import { selectBalance, selectCurrency } from '../store/wallet'

export const useBalance = () => {
  const { pathname } = useRouter()

  const { data: allCoinPrice, status: coinPriceStatus } = useGetCoinPrice(pathname)
  const currency = useAppSelector(selectCurrency)
  const { matic, wmatic, eth, weth } = useAppSelector(selectBalance)

  return {
    allCoinPrice,
    coinPriceStatus,
    currency,
    maticBalance: matic,
    wmaticBalance: wmatic,
    ethBalance: eth,
    wethBalance: weth,
  }
}
