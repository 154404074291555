import { useQuery } from '@tanstack/react-query'

import { PAYMENT_TYPE } from '@/shared/constants'
import {
  IAllCoinPrice,
  IBaseResponse,
  ICoinPriceEthResponse,
  ICoinPriceMaticResponse,
} from '@/shared/types'

import withAxios from '../api'

export const getCoinPrice = async (): Promise<IAllCoinPrice> => {
  const {
    data: {
      data: { ethToKrw, ethToUsd },
    },
  } = await withAxios<IBaseResponse<ICoinPriceEthResponse>>({
    url: '/api/v1/public/coin-prices/eth',
    method: 'GET',
  })
  const {
    data: {
      data: { maticToKrw, maticToUsd },
    },
  } = await withAxios<IBaseResponse<ICoinPriceMaticResponse>>({
    url: '/api/v1/public/coin-prices/matic',
    method: 'GET',
  })
  return {
    [PAYMENT_TYPE.ETH]: {
      krw: ethToKrw,
      usd: ethToUsd,
    },
    [PAYMENT_TYPE.WETH]: {
      krw: ethToKrw,
      usd: ethToUsd,
    },
    [PAYMENT_TYPE.MATIC]: {
      krw: maticToKrw,
      usd: maticToUsd,
    },
    [PAYMENT_TYPE.WMATIC]: {
      krw: maticToKrw,
      usd: maticToUsd,
    },
  }
}

export const useGetCoinPrice = (pathname: string) => {
  return useQuery([pathname, 'getCoinPrice'], () => getCoinPrice())
}
export default useGetCoinPrice
