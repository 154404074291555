import { PAYMENT_TYPE } from '../constants'

export const getPaymentIconPath = (paymentType: keyof typeof PAYMENT_TYPE) => {
  const iconPathMappings = {
    [PAYMENT_TYPE.ETH]: '/img/icon/ethereum.svg',
    [PAYMENT_TYPE.WETH]: '/img/icon/wrapped-ethereum.svg',
    [PAYMENT_TYPE.MATIC]: '/img/icon/matic.svg',
    [PAYMENT_TYPE.WMATIC]: '/img/icon/wmatic.svg',
  }

  return iconPathMappings[paymentType]
}
