import axios from 'axios'
import { useRouter } from 'next/router'

import {
  ManagedDialog,
  ORIGINALS_PAYMENT_ERROR_CODE,
  ORIGINALS_PAYMENT_TYPE,
  PAYMENTS_RESULT_STATUS,
  PORTONE_STATUS,
  ROUTE_PATH,
} from '../constants'
import {
  useUpdateOriginalsCheckoutBankPayments,
  useUpdateOriginalsCheckoutCardPayments,
  useUpdateOriginalsCheckoutPaypalPayments,
  useUpdateOriginalsPortOnePaymentsCallback,
} from '../services/originals'
import { usePostOriginalsFreePaymentsCallback } from '../services/originals/checkoutFreeCallback'
import {
  ICallbackError,
  IGetOriginalsPaymentCallbackResponse,
  IHandlePaymentErrorProps,
  IHandlePortOnePaymentSuccessProps,
  IPaymentsFuncProps,
  IPostOriginalsBankPaymentsResponse,
  IPostOriginalsCardPaymentsResponse,
  IPostOriginalsPaypalPaymentsResponse,
  IUpdateConvertedOriginalsCheckoutInfo,
} from '../types'
import { replaceParams } from '../utils'
import { useDialog } from './useDialog'

interface IUsePaymentsFuncProps {
  checkoutInfo?: IUpdateConvertedOriginalsCheckoutInfo
  setIsRequestingPayment?: (isRequestingPayment: boolean) => void
}

// 타입 가드 함수
const isCallbackError = (error: any): error is ICallbackError => {
  return (
    error &&
    typeof error.error === 'string' &&
    typeof error.message === 'string' &&
    typeof error.statusCode === 'number'
  )
}

const handlePortOnePaymentsSuccess = ({
  originalsId,
  callbackRes,
  setDialog,
  router,
  setIsRequestingPayment,
}: IHandlePortOnePaymentSuccessProps) => {
  const handleNavigate = (result: string, errorCodeOrOrdNo: string) => {
    router.replace(
      replaceParams(ROUTE_PATH.ORIGINALS_CHECKOUT_CALLBACK, {
        originalsId,
        result: result,
        errorCodeOrOrdNo,
      }),
    )
    setDialog(null)
    setIsRequestingPayment(false)
  }

  switch (callbackRes.status) {
    case PORTONE_STATUS.PAID:
    case PORTONE_STATUS.PENDING:
      handleNavigate(PAYMENTS_RESULT_STATUS.SUCCESS, callbackRes.ordNo)
      break
    default:
      if (callbackRes.resultMsg) {
        handleNavigate(PAYMENTS_RESULT_STATUS.FAIL, callbackRes.resultMsg)
      } else {
        handleNavigate(PAYMENTS_RESULT_STATUS.FAIL, 'COMMON_PAY_ERROR')
      }
  }
}

const handlePortOnePaymentsError = ({
  error,
  setDialog,
  router,
  originalsId,
  setIsRequestingPayment,
}: IHandlePaymentErrorProps) => {
  const handleNavigate = (errorType: string) => {
    const url = replaceParams(ROUTE_PATH.ORIGINALS_CHECKOUT_FAIL, {
      originalsId,
      errorCode: errorType,
    })
    router.replace(url)
    setDialog(null)
    setIsRequestingPayment(false)
  }

  if (axios.isAxiosError(error)) {
    const errorRes = error.response?.data
    const errorCode = errorRes?.error

    if (errorCode && isCallbackError(errorRes) && ORIGINALS_PAYMENT_ERROR_CODE[errorCode]) {
      handleNavigate(errorCode)
      return
    }
  }

  handleNavigate('COMMON_PAY_ERROR')
}
const makeFormDataForPayments = ({
  formName,
  dataList,
}: {
  formName: string
  dataList: IUpdateConvertedOriginalsCheckoutInfo
}) => {
  const previousFormValue = document.getElementsByName(`${formName}`)[0] as
    | HTMLFormElement
    | undefined

  if (previousFormValue) previousFormValue.remove()

  const body = document.getElementsByTagName('body')[0]
  const newFormValue = document.createElement('form')

  const exceptKeys = ['id', 'updatedAt', 'createdAt']

  newFormValue.setAttribute('name', `${formName}`)

  Object.entries(dataList).forEach(([key, value]) => {
    if (!exceptKeys.includes(key)) {
      const input = document.createElement('input')
      input.setAttribute('name', key)
      input.setAttribute('value', value)
      input.setAttribute('type', 'hidden')
      newFormValue.appendChild(input)
    }
  })
  body.appendChild(newFormValue)
  return newFormValue
}

export const usePayments = ({ paymentType, setIsRequestingPayment }: IPaymentsFuncProps) => {
  const router = useRouter()
  const { originalsId } = router.query as {
    originalsId?: string
  }
  const IMP = window.IMP
  const { mutateAsync: updateCheckoutBankInfo } = useUpdateOriginalsCheckoutBankPayments()
  const { mutateAsync: updateCheckoutCardInfo } = useUpdateOriginalsCheckoutCardPayments()
  const { mutateAsync: updateCheckoutPaypalInfo } = useUpdateOriginalsCheckoutPaypalPayments()
  const { mutateAsync: postCheckoutFreeCallback } = usePostOriginalsFreePaymentsCallback()

  const { mutateAsync: updatePortOnePaymentsCallback } = useUpdateOriginalsPortOnePaymentsCallback()

  const { setDialog } = useDialog()

  const isPaymentUnavailable = !originalsId || !paymentType

  const popupBankPayment = ({ setIsRequestingPayment, checkoutInfo }: IUsePaymentsFuncProps) => {
    if (!checkoutInfo || isPaymentUnavailable || !setIsRequestingPayment) return
    setDialog(ManagedDialog.waitingForPayments)
    setIsRequestingPayment(true)
    try {
      updateCheckoutBankInfo(checkoutInfo, {
        onSuccess: (checkoutBankInfoRes: IPostOriginalsBankPaymentsResponse) => {
          IMP.request_pay(checkoutBankInfoRes, function (req: any) {
            updatePortOnePaymentsCallback(
              { imp_uid: req.imp_uid, merchant_uid: req.merchant_uid },
              {
                onSuccess: callbackRes => {
                  setDialog(null)
                  if (callbackRes.status === PORTONE_STATUS.READY) {
                    setIsRequestingPayment(false)
                    return
                  }
                  handlePortOnePaymentsSuccess({
                    originalsId,
                    callbackRes,
                    setDialog,
                    router,
                    setIsRequestingPayment,
                  })
                },
                onError: error => {
                  handlePortOnePaymentsError({
                    error,
                    setDialog,
                    router,
                    originalsId,
                    setIsRequestingPayment,
                  })
                },
              },
            )
          })
        },
        onError: error => {
          handlePortOnePaymentsError({
            error,
            setDialog,
            router,
            originalsId,
            setIsRequestingPayment,
          })
        },
      })
    } catch (error) {
      handlePortOnePaymentsError({
        error,
        setDialog,
        router,
        originalsId,
        setIsRequestingPayment,
      })
    }
  }

  const popupCardPayment = ({ setIsRequestingPayment, checkoutInfo }: IUsePaymentsFuncProps) => {
    if (!checkoutInfo || isPaymentUnavailable || !setIsRequestingPayment) return
    setDialog(ManagedDialog.waitingForPayments)
    setIsRequestingPayment(true)
    try {
      updateCheckoutCardInfo(checkoutInfo, {
        onSuccess: (checkoutCardInfoRes: IPostOriginalsCardPaymentsResponse) => {
          IMP.request_pay(checkoutCardInfoRes, function (req: any) {
            updatePortOnePaymentsCallback(
              { imp_uid: req.imp_uid, merchant_uid: req.merchant_uid },
              {
                onSuccess: callbackRes => {
                  handlePortOnePaymentsSuccess({
                    originalsId,
                    callbackRes,
                    setDialog,
                    router,
                    setIsRequestingPayment,
                  })
                },
                onError: error => {
                  handlePortOnePaymentsError({
                    error,
                    setDialog,
                    router,
                    originalsId,
                    setIsRequestingPayment,
                  })
                },
              },
            )
          })
        },
        onError: error => {
          handlePortOnePaymentsError({
            error,
            setDialog,
            router,
            originalsId,
            setIsRequestingPayment,
          })
        },
      })
    } catch (error) {
      handlePortOnePaymentsError({
        error,
        setDialog,
        router,
        originalsId,
        setIsRequestingPayment,
      })
    }
  }

  const popupPayPalPayment = ({ setIsRequestingPayment, checkoutInfo }: IUsePaymentsFuncProps) => {
    if (
      !checkoutInfo ||
      isPaymentUnavailable ||
      !setIsRequestingPayment ||
      checkoutInfo.selectOptions.length < 1
    )
      return

    try {
      updateCheckoutPaypalInfo(checkoutInfo, {
        onSuccess: (checkoutPaypalInfoRes: IPostOriginalsPaypalPaymentsResponse) => {
          const dataWithBtnOption = {
            ...checkoutPaypalInfoRes,
            bypass: { paypal_v2: { 'disable-funding': 'card' } },
          }
          IMP.loadUI('paypal-spb', dataWithBtnOption, async (req: any) => {
            setDialog(ManagedDialog.waitingForPayments)
            setIsRequestingPayment(true)
            updatePortOnePaymentsCallback(
              { imp_uid: req.imp_uid, merchant_uid: req.merchant_uid },
              {
                onSuccess: callbackRes => {
                  handlePortOnePaymentsSuccess({
                    originalsId,
                    callbackRes,
                    setDialog,
                    router,
                    setIsRequestingPayment,
                  })
                },
                onError: error => {
                  handlePortOnePaymentsError({
                    error,
                    setDialog,
                    router,
                    originalsId,
                    setIsRequestingPayment,
                  })
                },
              },
            )
          })
        },
        onError: error => {
          handlePortOnePaymentsError({
            error,
            setDialog,
            router,
            originalsId,
            setIsRequestingPayment,
          })
        },
      })
    } catch (error) {
      handlePortOnePaymentsError({
        error,
        setDialog,
        router,
        originalsId,
        setIsRequestingPayment,
      })
    }
  }

  const getCheckoutFreeCallback = ({
    setIsRequestingPayment,
    checkoutInfo,
  }: IUsePaymentsFuncProps) => {
    if (!checkoutInfo || isPaymentUnavailable || !setIsRequestingPayment) return
    const formData = makeFormDataForPayments({
      formName: 'payForm',
      dataList: checkoutInfo,
    })
    const newFormData = new FormData(formData)
    const params = new URLSearchParams()
    for (const key of newFormData.keys()) {
      if (key !== null) {
        params.append(key, `${newFormData.get(key)}`)
      }
    }
    setDialog(ManagedDialog.waitingForPayments)
    postCheckoutFreeCallback(
      { formData: params },
      {
        onSuccess: (data: IGetOriginalsPaymentCallbackResponse) => {
          setDialog(null)
          const { url } = data
          router.replace(url)
        },
        onError: () => {
          setDialog(null)
        },
      },
    )
  }

  const mappingsPaymentFunc = {
    [ORIGINALS_PAYMENT_TYPE.MATIC]: () => undefined,
    [ORIGINALS_PAYMENT_TYPE.BANK]: popupBankPayment,
    [ORIGINALS_PAYMENT_TYPE.FREE]: getCheckoutFreeCallback,
    [ORIGINALS_PAYMENT_TYPE.CARD]: popupCardPayment,
    [ORIGINALS_PAYMENT_TYPE.CARD_EVENT]: popupCardPayment,
    [ORIGINALS_PAYMENT_TYPE.CARD_HYUNDAI]: popupCardPayment,
    [ORIGINALS_PAYMENT_TYPE.PAYPAL]: popupPayPalPayment,
  }

  return {
    paymentFunc: paymentType === null ? null : mappingsPaymentFunc[paymentType],
  }
}
