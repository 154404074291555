import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import bigDecimal from 'js-big-decimal'

import { getCollectionStats } from '@/shared/services/collections'
import { getInactiveListingList, getListingItem, getLowestPrice } from '@/shared/services/itemOrder'
import {
  CustomError,
  ICollectionStats,
  IListingItem,
  instanceOfCollectionStats,
  instanceOfListing,
  instanceOfListingItemList,
} from '@/shared/types'
import { getSnapshotInError } from '@/shared/utils'

import { AppState } from '..'

interface IPurchaseItemInfo {
  itemId: string
  collectionAddress: string
  tokenId: number | string
  collectionTitle: string
  itemTitle: string
  mediaUrl: string
  collectionStats: ICollectionStats | null
  listingItem: IListingItem | null
  inactiveListingList: IListingItem[] | null
  lackOfBalance: string | null
  chainId: string
}

export interface IPurchaseState {
  purchaseItemInfo: IPurchaseItemInfo | null

  isFetchingPurchaseItemInfo: boolean
  isFetchingPurchaseItemInfoError: string | null

  isPurchaseSigning: boolean
  isPurchaseSuccessful: boolean

  txHashForPurchase: string | null
  isPurchaseFailedForUserDenied: boolean
  isPurchaseError: string | null
}

const initialState: IPurchaseState = {
  purchaseItemInfo: null,

  isFetchingPurchaseItemInfo: false,
  isFetchingPurchaseItemInfoError: null,

  isPurchaseSigning: false,
  isPurchaseSuccessful: false,

  txHashForPurchase: null,
  isPurchaseFailedForUserDenied: false,
  isPurchaseError: null,
}

export const getPurchaseItemInfo = createAsyncThunk(
  'purchase/getPurchaseItemInfo',
  async ({
    itemId,
    collectionAddress,
    tokenId,
    collectionTitle,
    itemTitle,
    mediaUrl,
    chainId,
    balance,
  }: {
    itemId: string
    collectionAddress: string
    tokenId: number | string
    collectionTitle: string
    itemTitle: string
    mediaUrl: string
    chainId: string
    balance: {
      ETH: string | null
      USDC: string | null
      MATIC: string | null
    }
  }) => {
    const promises = [
      getCollectionStats(collectionAddress),
      getLowestPrice(itemId),
      getInactiveListingList(itemId),
    ]
    try {
      const [collectionStats, lowestPriceListing, inactiveListingList] = await Promise.all(promises)

      if (!instanceOfListing(lowestPriceListing)) {
        return Promise.reject('Lowest price listing is not valid')
      }

      const listingItem = await getListingItem(itemId, lowestPriceListing.id)

      const tokenBalance = balance[listingItem.listing.paymentType]

      let lackOfBalance: string | null = null
      if (bigDecimal.compareTo(tokenBalance, listingItem.listing.price) === -1) {
        lackOfBalance = bigDecimal.subtract(listingItem.listing.price, tokenBalance)
      }

      return {
        itemId,
        collectionAddress,
        tokenId,
        collectionTitle,
        itemTitle,
        mediaUrl,
        chainId,
        collectionStats: instanceOfCollectionStats(collectionStats) ? collectionStats : null,
        inactiveListingList: instanceOfListingItemList(inactiveListingList)
          ? inactiveListingList
          : null,
        listingItem,
        lackOfBalance,
      }
    } catch (e: unknown) {
      return Promise.reject(e)
    }
  },
)

export const purchaseSlice = createSlice({
  name: 'purchase',
  initialState,
  reducers: {
    resetPurchaseState: state => {
      state.purchaseItemInfo = null
      state.isFetchingPurchaseItemInfo = false
      state.isFetchingPurchaseItemInfoError = null
      state.isPurchaseSigning = false
      state.isPurchaseSuccessful = false
      state.isPurchaseError = null
      state.txHashForPurchase = null
      state.isPurchaseFailedForUserDenied = false
    },
    setPurchaseItemInfo: (state, action) => {
      state.purchaseItemInfo = action.payload
    },
    setIsPurchaseSigning(state, action: PayloadAction<boolean>) {
      state.isPurchaseSigning = action.payload
    },
    setIsPurchaseSuccessful(state, action: PayloadAction<boolean>) {
      state.isPurchaseSuccessful = action.payload
    },
    setIsPurchaseError(state, action: PayloadAction<string | null>) {
      state.isPurchaseError = action.payload
      if (action.payload !== null) {
        const snapshotInError = getSnapshotInError({
          ...(typeof window !== 'undefined' ? { pathname: window.location.pathname } : {}),
          extra: {
            purchaseState: state,
          },
        })
        new CustomError({
          name: 'PURCHASE_ERROR',
          message: action.payload,
        }).sendToSentry(snapshotInError)
      }
    },
    setTxHashForPurchase(state, action: PayloadAction<string | null>) {
      state.txHashForPurchase = action.payload
    },
    setIsPurchaseFailedForUserDenied(state, action: PayloadAction<boolean>) {
      state.isPurchaseFailedForUserDenied = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(getPurchaseItemInfo.pending, state => {
      state.purchaseItemInfo = null
      state.isFetchingPurchaseItemInfo = true
      state.isFetchingPurchaseItemInfoError = null
    })
    builder.addCase(getPurchaseItemInfo.fulfilled, (state, action) => {
      state.purchaseItemInfo = action.payload
      state.isFetchingPurchaseItemInfo = false
    })
    builder.addCase(getPurchaseItemInfo.rejected, (state, action) => {
      state.isFetchingPurchaseItemInfo = false
      if (action.error.message) {
        state.isFetchingPurchaseItemInfoError = action.error.message
      }
    })
  },
})

export const selectPurchaseItemInfo = (state: AppState) => state.purchase.purchaseItemInfo
export const selectIsFetchingPurchaseItemInfo = (state: AppState) =>
  state.purchase.isFetchingPurchaseItemInfo
export const selectIsFetchingPurchaseItemInfoError = (state: AppState) =>
  state.purchase.isFetchingPurchaseItemInfoError
export const selectIsPurchaseError = (state: AppState) => state.purchase.isPurchaseError

export const selectTxHashForPurchase = (state: AppState) => state.purchase.txHashForPurchase

export const selectPurchaseStatus = (state: AppState) => ({
  isPurchaseSuccessful: state.purchase.isPurchaseSuccessful,
  isPurchaseSigning: state.purchase.isPurchaseSigning,
  isPurchaseError: state.purchase.isPurchaseError,
  isPurchaseFailedForUserDenied: state.purchase.isPurchaseFailedForUserDenied,
})

export const {
  resetPurchaseState,
  setPurchaseItemInfo,
  setIsPurchaseError,
  setTxHashForPurchase,
  setIsPurchaseFailedForUserDenied,
  setIsPurchaseSigning,
  setIsPurchaseSuccessful,
} = purchaseSlice.actions

export default purchaseSlice
