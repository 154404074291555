import bigDecimal from 'js-big-decimal'

import { ORIGINALS_PAYMENT_TYPE } from '../constants'

export const checkAmountCondition = ({
  buyLimit,
  price,
  amount,
  paymentType,
}: {
  buyLimit: number
  price: number
  amount: number
  paymentType: keyof typeof ORIGINALS_PAYMENT_TYPE
}) => {
  const defaultCondition = amount < buyLimit
  const conditionMappings: Record<keyof typeof ORIGINALS_PAYMENT_TYPE, boolean> = {
    [ORIGINALS_PAYMENT_TYPE.MATIC]:
      paymentType === ORIGINALS_PAYMENT_TYPE.MATIC && defaultCondition,
    [ORIGINALS_PAYMENT_TYPE.BANK]:
      paymentType === ORIGINALS_PAYMENT_TYPE.BANK &&
      defaultCondition &&
      Number(bigDecimal.multiply(String(amount + 1), String(price))) <= 2000000,
    [ORIGINALS_PAYMENT_TYPE.CARD]: paymentType === ORIGINALS_PAYMENT_TYPE.CARD && defaultCondition,
    [ORIGINALS_PAYMENT_TYPE.CARD_HYUNDAI]:
      paymentType === ORIGINALS_PAYMENT_TYPE.CARD_HYUNDAI && defaultCondition,
    [ORIGINALS_PAYMENT_TYPE.CARD_EVENT]:
      paymentType === ORIGINALS_PAYMENT_TYPE.CARD_EVENT && defaultCondition,
    [ORIGINALS_PAYMENT_TYPE.FREE]: paymentType === ORIGINALS_PAYMENT_TYPE.FREE && defaultCondition,
    [ORIGINALS_PAYMENT_TYPE.PAYPAL]:
      paymentType === ORIGINALS_PAYMENT_TYPE.PAYPAL && defaultCondition,
  }

  return conditionMappings[paymentType]
}
