import BigDecimal from 'js-big-decimal'

import { IAllCoinPrice, ICoinPrice, PaymentType } from '../types'
import { numberFormat } from './formatter'

export const convertBalanceToCurrency = ({
  price,
  coinCurrency,
  currencyName,
}: {
  price: string
  coinCurrency: ICoinPrice
  currencyName: string
}): string => {
  const { usd, krw } = coinCurrency
  const COIN_PRICE_MAPPINGS: { [key: string]: number } = {
    USD: usd,
    KRW: krw,
  }
  const FIXED_POINT_MAPPINGS: { [key: string]: number } = {
    USD: 2,
    KRW: 0,
  }
  return Number(BigDecimal.multiply(price, COIN_PRICE_MAPPINGS[currencyName]))
    .toFixed(FIXED_POINT_MAPPINGS[currencyName])
    .toString()
}

/**
 *
 * @param {
 *   price: 가격(숫자 string),
 *   currency: { icon: '$' | '₩', name: 'USD' | 'KRW' }
 * }
 * @returns '1,500 KRW', '32.43 USD' 형태의 값
 */
export const makePriceForRender = ({
  price,
  currency,
}: {
  price: string
  currency: { icon: string; name: string }
}): string => {
  return `${numberFormat(price)} ${currency.name}`
}

/**
 * @param {
 * coinPrice: getCoinPrice의 응답값,
 * price: 입력값(string),
 * paymentType: 'ETH' | 'WETH' | 'USDC' | 'MATIC' | 'WMATIC',
 * currency: { icon: '$' | '₩', name: 'USD' | 'KRW' }
 *  }
 * @returns '1,500 KRW', '32.43 USD' 형태의 값
 */
export const convertBalanceToCurrencyWithSymbol = ({
  coinPrice,
  price,
  paymentType,
  currency,
}: {
  coinPrice?: IAllCoinPrice
  price: string
  paymentType: PaymentType
  currency: { icon: string; name: string }
}): string => {
  if (coinPrice === undefined) return ''
  return makePriceForRender({
    price: convertBalanceToCurrency({
      price,
      coinCurrency: coinPrice[paymentType],
      currencyName: currency.name,
    }),
    currency,
  })
}
