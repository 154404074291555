/* eslint-disable quotes */
import { ORIGINALS_PAYMENT_TYPE } from '../constants'

export const getBuyLimitText = ({
  buyLimit,
  selectedPaymentType,
  t,
}: {
  buyLimit: number
  selectedPaymentType: keyof typeof ORIGINALS_PAYMENT_TYPE
  t: (lng: string) => string
}) => {
  const paymentTextMappings = {
    [ORIGINALS_PAYMENT_TYPE.FREE]: `${t(
      'web-checkout:checkout.quantity-selection-description',
    ).replace('{value}', String(buyLimit))}`,
    [ORIGINALS_PAYMENT_TYPE.BANK]: [
      `${t('web-checkout:checkout.payment-method-bank-description')}`,
    ],
    [ORIGINALS_PAYMENT_TYPE.CARD]: [
      `${t('web-checkout:checkout.payment-method-card-description')}`,
    ],
    [ORIGINALS_PAYMENT_TYPE.CARD_EVENT]: [
      `${t('web-checkout:checkout.payment-method-card-description')}`,
    ],
    [ORIGINALS_PAYMENT_TYPE.MATIC]: [''],
    [ORIGINALS_PAYMENT_TYPE.CARD_HYUNDAI]: [
      `${t('web-checkout:checkout.payment-method-card-description')}`,
      `${t('web-checkout:checkout.payment-method-card-description-only-hc-no-discount')}`,
      `${t('web-checkout:checkout.payment-method-card-description-only-hc-no-point')}`,
    ],
    [ORIGINALS_PAYMENT_TYPE.PAYPAL]: [
      `${t('web-checkout:checkout.payment-method-paypal-description')}`,
      `${t('web-checkout:checkout.payment-method-paypal-description-2')}`,
    ],
  }

  return paymentTextMappings[selectedPaymentType]
}
