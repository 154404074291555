import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'

import {
  getAccountAddressForEthereum,
  getAccountAddressForPolygon,
  getEtherBalance,
  getMaticBalance,
  getWEtherBalance,
  getWMaticBalance,
} from '@/shared/services/ethers'
import { CustomError, IOauthTokenResponse, IWalletCurrentProfile } from '@/shared/types'
import { getSnapshotInError, sendMessageToApp, shortenAddress } from '@/shared/utils'

import { AppState, AppThunk } from '..'

export interface IWalletState {
  isConnecting: boolean
  accessToken: string | null
  isGetTokenError: string | null

  isGetBalanceLoading: boolean
  isGetBalanceError: string | null

  maticBalance: string | null
  wmaticBalance: string | null
  ethBalance: string | null
  wethBalance: string | null

  currentProfile: IWalletCurrentProfile | null

  originalsLink: string | null

  isRequestDisconnectWallet: boolean
}

const initialState: IWalletState = {
  isConnecting: false,
  accessToken: null,
  isGetTokenError: null,
  isGetBalanceLoading: false,
  isGetBalanceError: null,
  maticBalance: null,
  wmaticBalance: null,
  ethBalance: null,
  wethBalance: null,
  currentProfile: null,
  originalsLink: null,
  isRequestDisconnectWallet: false,
}

export const getToken = createAsyncThunk('wallet/getToken', async (code: string) => {
  try {
    const {
      data: { access_token: accessToken, refresh_token: refreshToken },
    } = await axios.post<IOauthTokenResponse>('/api/oauth/token', { code })
    sendMessageToApp<string>('Message', 'SuccessfulLogin')

    return { accessToken, refreshToken }
  } catch (e: unknown) {
    const snapshotInError = getSnapshotInError({
      ...(window !== undefined ? { pathname: window.location.pathname } : {}),
    })
    if (e instanceof CustomError) {
      new CustomError({
        name: 'LOGIN_ERROR',
        message: '인증 토큰을 가져오지 못했습니다.',
      }).sendToSentry(snapshotInError)
    }
    sendMessageToApp<string>('Message', 'FailedLogin')
    return Promise.reject(e)
  }
})

export const getBalance = createAsyncThunk('wallet/getBalance', async () => {
  try {
    const [accountAddressForMatic, accountAddressForEther] = await Promise.all([
      getAccountAddressForPolygon(),
      getAccountAddressForEthereum(),
    ])

    const [maticBalance, wmaticBalance, etherBalance, wetherBalance] = await Promise.all([
      getMaticBalance(accountAddressForMatic),
      getWMaticBalance(accountAddressForMatic),
      getEtherBalance(accountAddressForEther),
      getWEtherBalance(accountAddressForEther),
    ])

    return {
      maticBalance: maticBalance instanceof Error ? '0' : maticBalance,
      wmaticBalance: wmaticBalance instanceof Error ? '0' : wmaticBalance,
      etherBalance: etherBalance instanceof Error ? '0' : etherBalance,
      wetherBalance: wetherBalance instanceof Error ? '0' : wetherBalance,
    }
  } catch (e: unknown) {
    if (e instanceof Error) {
      const snapshotInError = getSnapshotInError({
        ...(window !== undefined ? { pathname: window.location.pathname } : {}),
      })
      new CustomError({
        name: 'GET_BALANCE_ERROR',
        message: '유저 지갑의 잔액을 가져오지 못했습니다.',
      }).sendToSentry(snapshotInError)
    }
    return Promise.reject(e)
  }
})

export const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    resetAuthState: state => {
      state.accessToken = null
      state.isGetTokenError = null
      state.isConnecting = false
      localStorage.removeItem('accessToken')
      localStorage.removeItem('refreshToken')
    },
    resetWalletState: state => {
      state.isGetBalanceLoading = false
      state.isGetBalanceError = null
      state.maticBalance = null
      state.wmaticBalance = null
      state.ethBalance = null
      state.wethBalance = null
      state.currentProfile = null
      state.isRequestDisconnectWallet = false
    },
    setCurrentProfileState: (state, action: PayloadAction<IWalletCurrentProfile | null>) => {
      state.currentProfile = action.payload
    },
    setOriginalsLink: (state, action) => {
      state.originalsLink = action.payload
    },
    setAccessToken: (state, action) => {
      state.accessToken = action.payload
    },
    setIsWalletConnecting: (state, action) => {
      state.isConnecting = action.payload
    },
    setIsRequestDisconnectWallet: (state, action) => {
      state.isRequestDisconnectWallet = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(getToken.pending, state => {
      state.isConnecting = true
      state.isGetTokenError = null
    })
    builder.addCase(getToken.fulfilled, (state, action) => {
      state.isConnecting = false
      state.accessToken = action.payload.accessToken
      localStorage.setItem('accessToken', action.payload.accessToken)
      localStorage.setItem('refreshToken', action.payload.refreshToken)
    })
    builder.addCase(getToken.rejected, (state, action) => {
      state.isConnecting = false
      if (action.error.message) state.isGetTokenError = action.error.message
    })
    builder.addCase(getBalance.pending, state => {
      state.isGetBalanceLoading = true
      state.isGetBalanceError = null
    })
    builder.addCase(getBalance.fulfilled, (state, action) => {
      state.isGetBalanceLoading = false
      const { maticBalance, wmaticBalance, etherBalance, wetherBalance } = action.payload
      state.maticBalance = maticBalance
      state.wmaticBalance = wmaticBalance
      state.ethBalance = etherBalance
      state.wethBalance = wetherBalance
    })
    builder.addCase(getBalance.rejected, (state, action) => {
      state.isGetBalanceLoading = false
      if (action.error.message) state.isGetBalanceError = action.error.message
    })
  },
})

export const selectIsWalletConnecting = (state: AppState) => state.wallet.isConnecting
export const selectIsConnectedWallet = (state: AppState) => {
  return state.wallet.accessToken !== null
}
export const selectCurrentAccountAddress = (state: AppState) =>
  state.wallet.currentProfile?.accountAddress ?? null
export const selectBalance = (state: AppState) => ({
  matic: state.wallet.maticBalance,
  wmatic: state.wallet.wmaticBalance,
  eth: state.wallet.ethBalance,
  weth: state.wallet.wethBalance,
})
export const selectIsGetTokenError = (state: AppState) => state.wallet.isGetTokenError
export const selectCurrentProfile = (state: AppState) => state.wallet.currentProfile
export const selectDisplayName = (state: AppState) => {
  const currentAccountAddress = state.wallet.currentProfile?.accountAddress ?? null
  if (currentAccountAddress === null) return null
  const currentProfile = state.wallet.currentProfile
  if (currentProfile === null) return null

  return currentProfile.userName !== null
    ? { type: 'userName', displayProfileName: currentProfile.userName }
    : { type: 'accountAddress', displayProfileName: shortenAddress(currentAccountAddress) }
}
export const selectOriginalsLink = (state: AppState) => state.wallet.originalsLink

export const selectIsRequestDisconnectWallet = (state: AppState) =>
  state.wallet.isRequestDisconnectWallet

export const {
  resetAuthState,
  resetWalletState,
  setOriginalsLink,
  setCurrentProfileState,
  setAccessToken,
  setIsWalletConnecting,
  setIsRequestDisconnectWallet,
} = walletSlice.actions

export const connectWallet =
  (code: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(resetAuthState())
    dispatch(resetWalletState())

    await dispatch(getToken(code))

    const isGetTokenError = selectIsGetTokenError(getState())
    if (isGetTokenError !== null) {
      dispatch(disconnectWallet())
    }

    await dispatch(getBalance())
  }

export const disconnectWallet = (): AppThunk => dispatch => {
  dispatch(resetAuthState())
  dispatch(resetWalletState())
}

export default walletSlice
