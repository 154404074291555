export const PAYMENT_TYPE = {
  ETH: 'ETH',
  WETH: 'WETH',
  MATIC: 'MATIC',
  WMATIC: 'WMATIC',
} as const

export const ETHER_LISTING_PAYMENT_TYPE = {
  ETH: 'ETH',
} as const

export const POLYGON_LISTING_PAYMENT_TYPE = {
  MATIC: 'MATIC',
} as const

export const LISTING_PAYMENT_TYPE = {
  ...ETHER_LISTING_PAYMENT_TYPE,
  ...POLYGON_LISTING_PAYMENT_TYPE,
} as const

export const ETHER_OFFER_PAYMENT_TYPE = {
  WETH: 'WETH',
} as const

export const POLYGON_OFFER_PAYMENT_TYPE = {
  WMATIC: 'WMATIC',
} as const

export const OFFER_PAYMENT_TYPE = {
  ...ETHER_OFFER_PAYMENT_TYPE,
  ...POLYGON_OFFER_PAYMENT_TYPE,
} as const

export const TRADE_TYPE = {
  LISTING: 'LISTING', // 판매 등록
  OFFER: 'OFFER', // 제안 등록
  LISTING_SALE: 'LISTING_SALE', // 거래 완료
  OFFER_SALE: 'OFFER_SALE', // 거래 완료
  LISTING_CANCEL: 'LISTING_CANCEL', // 판매 취소
  OFFER_CANCEL: 'OFFER_CANCEL', // 제안 취소
  TRANSFER: 'TRANSFER', // 전송
  MINTING: 'MINTING', // 민팅
} as const

export const TRADE_TYPE_NAME = {
  LISTING: '판매 등록',
  OFFER: '제안 등록',
  LISTING_SALE: '판매 성사',
  OFFER_SALE: '제안 성사',
  LISTING_CANCEL: '판매 취소',
  OFFER_CANCEL: '제안 취소',
  TRANSFER: '전송',
  MINTING: '발행',
}

export const ACTIVITY_TYPE_NAME = {
  LISTING: '판매 등록',
  OFFER: '제안',
  SALE: '거래 완료',
}

export const PAYMENT_TYPE_MAPPINGS_FOR_STAT = {
  WETH: 'ETH',
  WMATIC: 'MATIC',
}
