import { ZERO_ADDRESS } from '@modernlion/solidity-registry'

import { PAYMENT_TYPE } from '../constants'
import {
  DEV_WETH_ADDRESS,
  DEV_WMATIC_ADDRESS,
  PROD_WETH_ADDRESS,
  PROD_WMATIC_ADDRESS,
} from '../constants/tokenAddress'
import { PaymentType } from '../types'
import { getEnvValue } from './getEnvValue'

export const getTokenAddress = (paymentType: PaymentType): string => {
  const isDev = getEnvValue('mode') === 'development'

  const tokenAddressMappings = {
    [PAYMENT_TYPE.ETH]: ZERO_ADDRESS,
    [PAYMENT_TYPE.MATIC]: ZERO_ADDRESS,
    [PAYMENT_TYPE.WETH]: isDev ? DEV_WETH_ADDRESS : PROD_WETH_ADDRESS,
    [PAYMENT_TYPE.WMATIC]: isDev ? DEV_WMATIC_ADDRESS : PROD_WMATIC_ADDRESS,
  }

  return tokenAddressMappings[paymentType]
}
