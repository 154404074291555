import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { LISTING_PAYMENT_TYPE, POLYGON_LISTING_PAYMENT_TYPE } from '@/shared/constants'
import {
  FilterChip,
  HighestOfferForItem,
  IChipCollectionType,
  IChipListingType,
  IChipMaxPriceType,
  IChipMinPriceType,
  IChipOfferedType,
  ICollection,
  ListingPaymentType,
  OrderByType,
} from '@/shared/types'
import { isValidPrice } from '@/shared/utils'

export interface IAccountItemFilterState {
  isListing: boolean
  hasOffer: boolean
  paymentType?: ListingPaymentType
  paymentTypeList: ListingPaymentType[] | null
  minPrice?: string
  maxPrice?: string
  chips: FilterChip[]
  collections: IChipCollectionType[]
  order?: string
  orderBy: OrderByType
  selectedCollectionIds: string[]
  ownCollections: ICollection[]
  itemIdsWithOffers: string[]
  isClickChip: boolean
}

export const accountItemFilterState: IAccountItemFilterState = {
  isListing: false,
  hasOffer: false,
  paymentType: LISTING_PAYMENT_TYPE.MATIC,
  paymentTypeList: Object.values(POLYGON_LISTING_PAYMENT_TYPE),
  order: undefined,
  orderBy: 'DESC',
  chips: [],
  collections: [],
  ownCollections: [],
  selectedCollectionIds: [],
  itemIdsWithOffers: [],
  isClickChip: false,
}

export const accountItemFilterSlice = createSlice({
  name: 'accountItemFilter',
  initialState: accountItemFilterState,
  reducers: {
    initAccountItemFilter: () => accountItemFilterState,
    setIsClickChip: (state, action) => {
      state.isClickChip = action.payload
    },
    setPaymentTypeList: (state, action: PayloadAction<ListingPaymentType[] | null>) => {
      state.paymentTypeList = action.payload
    },
    toggleHasOffer: (state, action: PayloadAction<HighestOfferForItem>) => {
      state.hasOffer = !state.hasOffer

      if (state.hasOffer) {
        const newChip = {
          type: 'offered',
          isChecked: state.hasOffer,
        } as IChipOfferedType
        state.chips = [...state.chips, { offeredChip: newChip }]
      } else {
        state.chips = state.chips.filter(chip => {
          return chip?.offeredChip?.type !== 'offered'
        })
      }

      const itemIdsWithOffers = Object.keys(action.payload)
        .map((itemId: string) => {
          return action.payload[itemId] !== null ? itemId : false
        })
        .filter(Boolean) as string[]
      state.itemIdsWithOffers = itemIdsWithOffers
    },
    toggleIsListing: state => {
      state.isListing = !state.isListing

      if (state.isListing) {
        const newChip = {
          type: 'listing',
          isListing: state.isListing,
        } as IChipListingType
        state.chips = [...state.chips, { listingChip: newChip }]
      } else {
        state.chips = state.chips.filter(chip => {
          return chip?.listingChip?.type !== 'listing'
        })
      }
    },
    setOrderAndOrderBy: (state, action) => {
      state.order = action.payload.order
      state.orderBy = action.payload.orderBy
    },
    initPrice: state => {
      state.minPrice = undefined
      state.maxPrice = undefined
      state.chips = state.chips
        .filter(chip => chip.maxPriceChip === undefined)
        .filter(chip => chip.minPriceChip === undefined)
    },
    setMaxPrice: (state, action) => {
      if (state.paymentType === undefined) {
        return
      }

      if (!isValidPrice(action.payload, state.paymentType)) {
        return
      }

      state.maxPrice = action.payload

      if (state.maxPrice) {
        const newChip = {
          type: 'maxPrice',
          price: state.maxPrice,
          paymentType: state.paymentType,
        } as IChipMaxPriceType

        state.chips = state.chips.filter(chip => {
          return chip?.maxPriceChip?.type !== 'maxPrice'
        })
        state.chips = [...state.chips, { maxPriceChip: newChip }]
      } else {
        state.chips = state.chips.filter(chip => {
          return chip?.maxPriceChip?.type !== 'maxPrice'
        })
      }
    },
    setMinPrice: (state, action) => {
      if (state.paymentType === undefined) {
        return
      }

      if (!isValidPrice(action.payload, state.paymentType)) {
        return
      }

      state.minPrice = action.payload

      if (state.minPrice) {
        const newChip = {
          type: 'minPrice',
          price: state.minPrice,
          paymentType: state.paymentType,
        } as IChipMinPriceType

        state.chips = state.chips.filter(chip => {
          return chip?.minPriceChip?.type !== 'minPrice'
        })
        state.chips = [...state.chips, { minPriceChip: newChip }]
      } else {
        state.chips = state.chips.filter(chip => {
          return chip?.minPriceChip?.type !== 'minPrice'
        })
      }
    },
    setPaymentType: (state, action) => {
      const oldPaymentType = state.paymentType
      const newPaymentType = action.payload.paymentType
      state.paymentType = newPaymentType

      if (oldPaymentType !== newPaymentType) {
        state.maxPrice = ''
        state.minPrice = ''

        state.chips = state.chips.filter(chip => {
          return chip?.maxPriceChip?.type !== 'maxPrice'
        })
        state.chips = state.chips.filter(chip => {
          return chip?.minPriceChip?.type !== 'minPrice'
        })
      }
    },
    setOwnCollections: (state, action) => {
      state.ownCollections = action.payload || []
    },
    removeChip: (state, action) => {
      const chipToRemove = action.payload
      if (Object.keys(chipToRemove)[0] === 'offeredChip') {
        state.hasOffer = false
      }
      if (Object.keys(chipToRemove)[0] === 'listingChip') {
        state.isListing = false
      }
      if (Object.keys(chipToRemove)[0] === 'minPriceChip') {
        state.minPrice = ''
      }
      if (Object.keys(chipToRemove)[0] === 'maxPriceChip') {
        state.maxPrice = ''
      }

      state.chips = state.chips.filter(chip => {
        return JSON.stringify(chip) !== JSON.stringify(chipToRemove)
      })
    },
    toggleSelectedCollection: (state, action) => {
      const collectionAddress = action.payload.collectionAddress
      if (state.selectedCollectionIds.includes(collectionAddress)) {
        state.selectedCollectionIds = state.selectedCollectionIds.filter(
          id => id !== collectionAddress,
        )
        state.collections = state.collections.filter(
          collection => collection.address !== collectionAddress,
        )
      } else {
        const tmp = state.ownCollections.filter(
          collection => collection.collectionAddress === collectionAddress,
        )
        const selectedCollection = tmp.length > 0 ? tmp[0] : undefined
        if (selectedCollection) {
          state.selectedCollectionIds = [...state.selectedCollectionIds, collectionAddress]
          state.collections = [
            ...state.collections,
            {
              name: selectedCollection.title,
              address: selectedCollection.collectionAddress,
              type: 'collection',
            } as IChipCollectionType,
          ]
        }
      }
    },
    removeCollectionFilter: (state, action) => {
      const collectionAddress = action.payload
      state.collections = state.collections.filter(
        collection => collection.address !== collectionAddress,
      )
      state.selectedCollectionIds = state.selectedCollectionIds.filter(
        address => address !== collectionAddress,
      )
    },
  },
})
