import { PAYMENT_TYPE } from '../constants'
import { PaymentType } from '../types'

export const priceValueTransformer = (paymentType: PaymentType) => {
  const regExpForETHPrice = /^\d{1,9}(\.\d{0,3})?$/
  const regExpForMaticPrice = /^\d{1,9}(\.\d{0,2})?$/

  return {
    input: (value: string) => (isNaN(Number(value)) ? '' : value),
    output: (value: string) => {
      let transformValue = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
      if (
        (paymentType === PAYMENT_TYPE.ETH || paymentType === PAYMENT_TYPE.WETH) &&
        !regExpForETHPrice.test(transformValue)
      ) {
        transformValue = transformValue.substring(0, transformValue.length - 1)
      } else if (paymentType === PAYMENT_TYPE.MATIC && !regExpForMaticPrice.test(transformValue)) {
        transformValue = transformValue.substring(0, transformValue.length - 1)
      }
      return transformValue
    },
  }
}
